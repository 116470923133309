import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BillingOfficeService {
  headers: { headers: any };

  constructor(private _http: HttpClient) { }

  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return (this.headers = httpOptions);
  }

  //To Get Customers Billing Office By Customer
  getBillingOffice(CustId) {
    return this._http.get(
      environment.apiUrl + "api/get-CustomersBillingOffice/" + CustId
    );
  }

  //To Upsert Billing Office
  UpsertBillingOffice(data) {
    return this._http.post(
      environment.apiUrl + "api/upsert-CustomersBillingOffice",
      data
    );
  }

  //To Delete Billing Office
  DeleteBillingOffice(data) {
    return this._http.post(
      environment.apiUrl + "api/delete-CustomersBillingOffice",
      data
    );
  }
  //To Get List Of States
  StatesDropDownList() {
    return this._http.get(environment.apiUrl + "api/get-stateslist");
  }

  //To Get List Of Branches Linked With States
  BranchesByStatesDropDownList(StateId) {
    return this._http.get(
      environment.apiUrl + "api/get-branches-list-by-states-id/" + StateId
    );
  }

  //To Get List Of Zones linked With Branches
  ZonesByBranchesDropDownList(BranchId) {
    return this._http.get(
      environment.apiUrl + "api/get-zones-by-branch-id/" + BranchId
    );
  }
  //To Get State Code By State
  StateCodeByStateId(StateId) {
    return this._http.get(
      environment.apiUrl + "api/get-state-code-by-state-id/" + StateId
    );
  }

  UpdateBillingOfficeStatus(data) {
    return this._http.post(
      environment.apiUrl + "api/update-customers-billing-office-status",
      data
    );
  }

  GetBillingOffficeHistory(BillingOfficeId) {
    return this._http.get(
      environment.apiUrl +
      "api/get-customers-billing-office-history/" +
      BillingOfficeId
    );
  }

  CheckBillingOfficeName(CustId, Office) {
    return this._http.get(
      environment.apiUrl + `api/check-billing-office/${CustId}/${Office}`
    );
  }
}
