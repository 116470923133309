import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EwaybillAlertMenuService {

  
  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

    // to get confirmed orders for bookings
    getExpiryNotificationOrders(filter) {
      return this._http.post(environment.apiUrl + 'api/get-orders-for-expiry-notification', filter);
    }  

    // To get Records of EwayBill Exception Report
    getEwayBillExceptionReport(filter) {
      return this._http.post(environment.apiUrl + 'api/get-ewaybill-exception-report', filter)
    }

}
