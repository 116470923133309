import { AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

export class WhiteSpaceValidator {
    static cannotContainSpace(control: FormControl) {
        // console.log(control.value, control) 

        if(control.value == null){
            return null;
        }
        const isWhitespace = (control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'cannotContainSpace': true };
    }
}
