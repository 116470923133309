import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LclBillingService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // getToPayOrdersLCL(data){
  //   return this._http.post(environment.apiUrl + 'api/get-lcl_to_pay_orders_for_billing', data);
  // }
  getToPayOrdersLCL(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetToPayOrdersForBillingLCL', data);
  }

  // getPaidOrdersLCL(data){
  //   return this._http.post(environment.apiUrl + 'api/get-lcl_paid_orders_for_billing', data);
  // }
  getPaidOrdersLCL(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetPaidOrdersForBillingLCL', data);
    }

  save_lcl_paid_bill(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-paidlcl-bill', data);
  }

  getLCLBreakbulkPaidOrders(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetPaidBreakbulkOrdersLCL', data);
  }

  getLCLBreakbulkTopayOrders(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetToPayBreakbulkOrdersLCL', data);
  }
}
