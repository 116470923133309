import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseModel } from 'src/app/_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class FCLService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // to get orders for dashboard
  getOrdersForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-dashboard', data);
  }
  getOrdersCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-count-for-dashboard', data);
  }

  getOrdersFreightCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-freight-count-for-dashboard', data);
  }

  getOrdersForDashboard2(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-dashboard-stats', data);
  }
// for pending approvals
  updatefreightforpendingapprovals(data) {
    return this._http.post(environment.apiUrl + 'api/update-freight-pending-approvals', data);
  }
  updatependingapprovals(data) {
    return this._http.post(environment.apiUrl + 'api/update-pending-approvals', data);
  }

  updateMultipendingapprovals(data) {
    return this._http.post(environment.apiUrl + 'api/update-muliti-pending-approvals', data);
  }

  // to get XPCNs of an Order in dashboard
  getXPCNsForOrdersInDashboard(id) {
    return this._http.get(environment.apiUrl + 'api/get-xpcns-orders-dashboard/' + id);
  }

  getLifecycleOfOrder(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-lifecycle-of-order/' + orderId);
  }

  getOrdersForPendingApprovals(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-pending-approvals', data);
  }

  getOrdersForMatricesCSV(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-matrices-csv-data', data);
  }
  GetOrdersCount(){
    return this._http.get(environment.apiUrl + 'api/get-manage-orders-count' )
  }

  undoCancelReject(orderId) {
    return this._http.get(environment.apiUrl + 'api/undo-cancel-reject?orderId=' + orderId);
  }
}
